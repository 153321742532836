export default {
	wad: {
		97: '0x29f9E7f1ACE4E3496c0c46b3191E800a7A77964E',
		56: '0x0fEAdcC3824E7F3c12f40E324a60c23cA51627fc',
	},
	syrup: {
		97: '0x3E11A30d9D5892030B0Ed20A3AD9CEF0e9a4d559',
		56: '0x372Aed0528EA4BC95E1101B2882Cb15FcA4b226E',
	},
	masterChef: {
		97: '0x66E083a3d0E361152142B1887b99c57FA60Afb96',
		56: '0xde866dD77b6DF6772e320dC92BFF0eDDC626C674',
	},
	sousChef: {
		97: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
		56: '0x6ab8463a4185b80905e05a9ff80a2d6b714b9e95',
	},
	wbnb: {
		97: '0xae13d989dac2f0debff460ac112a837c89baa7cd',
		56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
	},
	lottery: {
		97: '0x99c2EcD51d52c036B00130d882Bc65f20Fdecf9f',
		56: '0x3C3f2049cc17C136a604bE23cF7E42745edf3b91',
	},
	lotteryNFT: {
		97: '0x8175c10383511b3a1C68f9dB222dc14A19CC950e',
		56: '0x5e74094Cd416f55179DBd0E45b1a8ED030e396A1',
	},
	mulltiCall: {
		56: '0x1ee38d535d541c55c9dae27b12edf090c608e6fb',
		97: '0x67ADCB4dF3931b0C5Da724058ADC2174a9844412',
	},
	busd: {
		56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
		97: '0x2DbC5dFCe92737D8c333296F9F29a166a1aED742',
	},
	// ust: {
	//   56: '0x23396cF899Ca06c4472205fC903bDB4de249D6fC',
	//   97: '',
	// },
	pancakeProfile: {
		56: '0xDf4dBf6536201370F95e06A0F8a7a70fE40E388a',
		97: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
	},
	pancakeRabbits: {
		56: '0xDf7952B35f24aCF7fC0487D01c8d5690a60DBa07',
		97: '0x60935F36e4631F73f0f407e68642144e07aC7f5E',
	},
	bunnyFactory: {
		56: '0xfa249Caa1D16f75fa159F7DFBAc0cC5EaB48CeFf',
		97: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
	},
	// eth: {
	//   56: '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
	//   97: '',
	// },
	// claimRefund: {
	//   56: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
	//   97: '',
	// },
	pointCenterIfo: {
		56: '0x3C6919b132462C1FEc572c6300E83191f4F0012a',
		97: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
	},
	bunnySpecial: {
		56: '0xFee8A195570a18461146F401d6033f5ab3380849',
		97: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
	},
	usdt: {
		56: '0x55d398326f99059fF775485246999027B3197955',
		97: '',
	},
};
