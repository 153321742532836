import { useEffect } from 'react';
import { usePriceCakeBusd } from 'state/hooks';

const useGetDocumentTitlePrice = () => {
	const cakePriceUsd = usePriceCakeBusd();
	useEffect(() => {
		document.title = `WARDEN SWAP Best Price Swap on Binance Smart Chain - $${Number(cakePriceUsd).toLocaleString(
			undefined,
			{
				minimumFractionDigits: 3,
				maximumFractionDigits: 3,
			},
		)}`;
	});
};
export default useGetDocumentTitlePrice;
